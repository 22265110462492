import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Build force directed graphs with React and D3v7",
  "description": "Force-directed graphs are D3's magic trick. They look cool and solve a big problem – layout. How do you make it work with React though?",
  "date": "2021-08-10T08:00:00.000Z",
  "published": "2021-08-10T08:00:00.000Z",
  "image": "./force-directed-graph-with-react-and-d3v7.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A common question readers ask is how to make D3 force-directed graphs work with React. It's a little tricky.`}</p>
    <p>{`You'd think it's obvious – use D3 to drive the force simulation, React for rendering. That's the approach I teach in `}<a parentName="p" {...{
        "href": "https://reactfordataviz.com"
      }}>{`React For Dataviz`}</a>{`.`}</p>
    <p>{`But something changed in recent versions of D3 and I racked my brain for hours. It nearly killed me. 😂`}</p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`this was fun to build`}<br parentName="blockquote"></br><br parentName="blockquote"></br>{`and by fun I mean that multi-focal force graphs in React and D3 almost broke me 😅 `}<br parentName="blockquote"></br><br parentName="blockquote"></br>{`time to expand , looks like we got a new chapter for the cookbook! `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/ext_tw_video_thumb/1422237131104083970/pu/img/nqlw6k1zXDiyhwVf.jpg",
            "width": "100%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1422237471278960647"
          }}>{`4:46:51 PM – 8/2/2021`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=1422237471278960647",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`13`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1422237471278960647",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`0`}</a></div>{`
    `}</div></div>
    <h2 {...{
      "id": "what-are-force-directed-graphs"
    }}>{`What are force-directed graphs`}</h2>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Force-directed_graph_drawing"
      }}>{`Force-directed graphs`}</a>{` are one of `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-force/tree/v3.0.0"
      }}>{`D3's magic tricks`}</a>{`. A feature that demos nicely and solves an important problem.`}</p>
    <p>{`You can use them to layout complex data.`}</p>
    <p><a parentName="p" {...{
        "href": "https://observablehq.com/@d3/force-directed-tree"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/LRjQIOz.gif",
          "alt": "Force-directed tree"
        }}></img></a></p>
    <p>{`Simulate collisions.`}</p>
    <p><a parentName="p" {...{
        "href": "https://observablehq.com/@d3/collision-detection"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/6MUjWTM.gif",
          "alt": "Collision detection"
        }}></img></a></p>
    <p>{`And even cloth simulations, if you squint hard enough.`}</p>
    <p><a parentName="p" {...{
        "href": "https://observablehq.com/@d3/force-directed-lattice"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/z3kPFfS.gif",
          "alt": "Cloth simulation"
        }}></img></a></p>
    <p>{`Finding the best layout for a complex graph is hard. Even impossible in some cases. Force-directed graphs solve the problem by simulating physical forces between nodes, which leads to visually pleasing results.`}</p>
    <p>{`The downside is that you have to wait for the simulation. You can pre-generate the final result before rendering, but you're waiting at some point somewhere.`}</p>
    <p>{`Luckily the animations look nice 😊`}</p>
    <h2 {...{
      "id": "build-a-force-directed-graph-with-react-and-d3"
    }}>{`Build a force-directed graph with React and D3`}</h2>
    <p>{`D3's `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-force/tree/v3.0.0"
      }}>{`d3-force`}</a>{` module gives you the tools to simulate forces.`}</p>
    <p>{`You create a new simulation with `}<inlineCode parentName="p">{`d3.forceSimulation()`}</inlineCode>{`, add different forces with `}<inlineCode parentName="p">{`.force('name', func)`}</inlineCode>{`, pass-in data with `}<inlineCode parentName="p">{`.nodes()`}</inlineCode>{`, and update your visuals on each tick of the animation with `}<inlineCode parentName="p">{`.on('tick', func)`}</inlineCode>{`.`}</p>
    <p>{`Where it gets tricky in a React context is that `}<inlineCode parentName="p">{`d3.forceSimulation()`}</inlineCode>{` assumes it's running on DOM nodes directly. It wants to change attributes.`}</p>
    <h3 {...{
      "id": "force-less-react--d3-force-directed-graph"
    }}>{`Force-less React & D3 force-directed graph`}</h3>
    <p>{`Let's start with a force simulation that has no forces.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/force-less-graph-vsve2",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`Nothing happens so the simulation is not very interesting. But it sets us up.`}</p>
    <p>{`We have a `}<inlineCode parentName="p">{`ForceGraph`}</inlineCode>{` component that accepts a list of `}<inlineCode parentName="p">{`nodes`}</inlineCode>{` as its prop and renders a circle for each. This is the "React handles rendering" part.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function ForceGraph({ nodes }) {
  const [animatedNodes, setAnimatedNodes] = useState([])

  // ...

  return (
    <g>
      {animatedNodes.map((node) => (
        <circle
          cx={node.x}
          cy={node.y}
          r={node.r}
          key={node.id}
          stroke="black"
          fill="transparent"
        />
      ))}
    </g>
  )
}
`}</code></pre>
    <p>{`We render from `}<inlineCode parentName="p">{`animatedNodes`}</inlineCode>{` in state instead of the `}<inlineCode parentName="p">{`nodes`}</inlineCode>{` prop. Our force simulation is going to change these values over time, which means they have to become part of state.`}</p>
    <p>{`The animation runs in a `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// re-create animation every time nodes change
useEffect(() => {
  const simulation = d3.forceSimulation()

  // update state on every frame
  simulation.on("tick", () => {
    setAnimatedNodes([...simulation.nodes()])
  })

  // copy nodes into simulation
  simulation.nodes([...nodes])
  // slow down with a small alpha
  simulation.alpha(0.1).restart()

  // stop simulation on unmount
  return () => simulation.stop()
}, [nodes])
`}</code></pre>
    <p>{`Any time the `}<inlineCode parentName="p">{`ForceGraph`}</inlineCode>{` component mounts or the `}<inlineCode parentName="p">{`nodes`}</inlineCode>{` prop changes, we start a new simulation.`}</p>
    <p>{`Visual updates happen by updating component state on every tick of the animation. You can think of these as frames.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// update state on every frame
simulation.on("tick", () => {
  setAnimatedNodes([...simulation.nodes()])
})
`}</code></pre>
    <p>{`You `}<em parentName="p">{`have to`}</em>{` make a copy of the nodes array or React won't realize there's a change. `}<inlineCode parentName="p">{`d3.forceSimulation`}</inlineCode>{` mutates state in-place, which fails the JavaScript equality test – it's the same object.`}</p>
    <p><inlineCode parentName="p">{`simulation.nodes()`}</inlineCode>{` returns the current state of our simulation.`}</p>
    <p>{`We return `}<inlineCode parentName="p">{`simulation.stop()`}</inlineCode>{` to clean up after ourselves when the component unmounts or a new effect starts.`}</p>
    <h3 {...{
      "id": "add-a-centering-force"
    }}>{`Add a centering force`}</h3>
    <p>{`Let's add a centering force to make our visualization more visible. You can have one centering force per graph.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/centering-force-4gg33",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`We've got some action now! The cluster of nodes moves towards the `}<inlineCode parentName="p">{`(400, 300)`}</inlineCode>{` coordinates at the center of our SVG.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const simulation = d3
  .forceSimulation()
  .force("x", d3.forceX(400))
  .force("y", d3.forceY(300))
`}</code></pre>
    <p><inlineCode parentName="p">{`d3.forceX`}</inlineCode>{` and `}<inlineCode parentName="p">{`d3.forceY`}</inlineCode>{` create a 1-dimensional force towards the given coordinate. This force acts on every node in your graph and you can't change that. I tried.`}</p>
    <p>{`If you want a multi-focal graph like in my tweet, you'll need to overlay multiple `}<inlineCode parentName="p">{`ForceGraph`}</inlineCode>{` components. Each with its own centering force.`}</p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`this was fun to build`}<br parentName="blockquote"></br><br parentName="blockquote"></br>{`and by fun I mean that multi-focal force graphs in React and D3 almost broke me 😅 `}<br parentName="blockquote"></br><br parentName="blockquote"></br>{`time to expand , looks like we got a new chapter for the cookbook! `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/ext_tw_video_thumb/1422237131104083970/pu/img/nqlw6k1zXDiyhwVf.jpg",
            "width": "100%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1422237471278960647"
          }}>{`4:46:51 PM – 8/2/2021`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=1422237471278960647",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`13`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1422237471278960647",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`0`}</a></div>{`
    `}</div></div>
    <p>{`A neat trick would be to define the centering coordinates with a mouse click. I'll let you play with that, you can get inspiration for correctly detecting mouse position from my `}<a parentName="p" {...{
        "href": "https://reactfordataviz.com/articles/mouse-events-with-d3v6-react-hooks/"
      }}>{`Free-hand mouse drawing with D3v6 and React Hooks`}</a>{` article.`}</p>
    <h3 {...{
      "id": "add-a-many-body-force-to-repel-or-attract-nodes"
    }}>{`Add a many-body force to repel or attract nodes`}</h3>
    <p>{`A many-body force lets nodes push or pull against each other.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/many-body-force-pox8p",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`You can think of this force as a "charge" between electrons.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const simulation = d3
  .forceSimulation()
  .force("x", d3.forceX(400))
  .force("y", d3.forceY(300))
  .force("charge", d3.forceManyBody().strength(2))
`}</code></pre>
    <p>{`A positive value makes nodes attract, negative pushes them apart. The bigger the value the stronger the force.`}</p>
    <p>{`Try the slider to see what happens :)`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/gkZU0je.gif",
        "alt": "Playing with the slider to adjust manyBodyForce strength"
      }}></img></p>
    <p>{`We wrapped the initial node generation in a `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` to avoid re-creating the nodes when you change the slider. That way `}<inlineCode parentName="p">{`d3.forceSimulation()`}</inlineCode>{` can look stable through changes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const nodes = useMemo(
  () =>
    d3.range(50).map((n) => {
      return { id: n, r: 5 }
    }),
  []
)
`}</code></pre>
    <h3 {...{
      "id": "add-collisions-to-set-smallest-distance-between-nodes"
    }}>{`Add collisions to set smallest distance between nodes`}</h3>
    <p>{`A high charge looked weird and the nodes eventually overlapped into a single circle. That's not good.`}</p>
    <p>{`We add a collision force to ensure that can't happen.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/collision-force-ksbhk",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`No matter how hard you squeeze, the nodes won't overlap 💪`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/OK75l5r.gif",
        "alt": "Nodes can't overlap"
      }}></img></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const simulation = d3
  .forceSimulation()
  .force("x", d3.forceX(400))
  .force("y", d3.forceY(300))
  .force("charge", d3.forceManyBody().strength(charge))
  .force("collision", d3.forceCollide(5))
`}</code></pre>
    <p><inlineCode parentName="p">{`d3.forceCollide`}</inlineCode>{` takes an argument that specifies collision radius. We set it at `}<inlineCode parentName="p">{`5`}</inlineCode>{` because that's how big our nodes are. You can pass a function to adjust this radius for individual nodes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`d3.forceCollide((node) => node.r)
`}</code></pre>
    <p>{`That lets you support different sizes, enforce a gap, or allow some overlap.`}</p>
    <h2 {...{
      "id": "what-about-links-between-nodes"
    }}>{`What about links between nodes?`}</h2>
    <p>{`Links work similarly. You'll need a list of links between nodes, like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
    source: node.id,
    target: node.id
}
`}</code></pre>
    <p>{`Pass it into your simulation with `}<inlineCode parentName="p">{`.links()`}</inlineCode>{` and create a link force using `}<inlineCode parentName="p">{`force('links', d3.forceLink())`}</inlineCode>{`. Usually this is an attracting force but it doesn't have to be! Go wild`}</p>
    <p>{`Rendering your links is similar to rendering your nodes: Iterate through the links, render an element. Lines are a popular choice.`}</p>
    <p>{`Don't forget to update your animation tick to re-render links.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// update state on every frame
simulation.on("tick", () => {
  setAnimatedNodes([...simulation.nodes()])
  setAnimatedLinks([...simulation.links()])
})
`}</code></pre>
    <p>{`React ensures both updates happen in the same frame.`}</p>
    <p>{`Give it a shot and lemme know if that doesn't work. Exercise for the reader and all that 😛`}</p>
    <p>{`Cheers,`}<br />{`
~Swizec`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      